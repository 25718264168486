<template>
  <modal :name="modalName" transition="fade" width="700" height="auto" draggable="true" @before-open="beforeOpen"
    :adaptive="true">
    <div class="modal-container">
      <div class="modal-header">
        <h3>Create Incident</h3>
        <button @click=close>X</button>
      </div>
      <div class="modal-content">
        <div class="modal-intro-information">
          <label>Title:</label>
          <input ref="title" type="text" placeholder="Incident Title" class="modal-incident-title" v-model="title" />
          <label>Description:</label>
          <textarea ref="description" type="text" placeholder="Description" class="modal-incident-description"
            maxlength="200" rows="5" v-model="description"></textarea>
        </div>
        <div class="modal-create-data">
          <div class="modal-create-data-item">
            <p>Incident Type</p>
            <div>
              <div v-if="showTypes">
                <ul @mouseleave="showTypes = false">
                  <li v-for="type in types" :key="type" @click="setType(type)">
                    {{ type }}
                  </li>
                </ul>
              </div>
              <button v-else ref="type" @click="showTypes = !showTypes"> {{ selectedType }}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Incident Status</p>
            <div>
              <div v-if="showStatuses">
                <ul @mouseleave="showStatuses = false">
                  <li v-for="status in statuses" :key="status" @click="setStatus(status)">
                    {{ status }}
                  </li>
                </ul>
              </div>
              <button v-else ref="status" @click="showStatuses = !showStatuses"> {{ selectedStatus }}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Incident Priority</p>
            <div>
              <div v-if="showPriorities">
                <ul @mouseleave="showPriorities = false">
                  <li v-for="priority in priorities" :key="priority" @click="setPriority(priority)">
                    {{ priority }}
                  </li>
                </ul>
              </div>
              <button v-else ref="priority" @click="showPriorities = !showPriorities"> {{ selectedPriority }}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Location</p>
            <div>
              <div v-if="showLocations">
                <ul @mouseleave="showLocations = false">
                  <li v-for="location in locations" :key="location" @click="setLocation(location)">
                    {{ location }}
                  </li>
                </ul>
              </div>
              <button v-else ref="location" @click="showLocations = !showLocations"> {{ selectedLocation }}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Associated Resident</p>
            <div>
              <div v-if="showResidents">
                <ul @mouseleave="showResidents = false">
                  <li v-for="resident in residents" :key="resident.uid" @click="setSelectedResident(resident)">
                    {{ `${resident.first_name} ${resident.last_name} (${resident.subdivision})` }}
                  </li>
                </ul>
              </div>
              <button v-else ref="resident" @click="showResidents = !showResidents"> {{
                getSelectedResidentText()}}</button>
            </div>
          </div>
          <div class="modal-create-data-item">
            <p>Reported By</p>
            <button>{{ getReporterText() }}</button>
          </div>
          <div class="modal-create-data-item">
            <p>Assigned To</p>
            <div v-if="showAssignees">
              <ul @mouseleave="showAssignees = false">
                <li v-for="user in residents" :key="user.uid" @click="setAssignee(user)">
                  {{ `${user.first_name} ${user.last_name} (${user.subdivision})` }}
                </li>
              </ul>
            </div>
            <button v-else ref="assignee" @click="showAssignees = !showAssignees">{{ getSelectedAssigneeText() }}</button>
          </div>


        </div>
      </div>
      <div class="modal-footer">
        <button @click="createIncident()">Create Incident</button>
      </div>
    </div>
  </modal>
</template>

<script>
import * as Constants from "../../js/constants.js";
const NAME = Constants.CREATE_INCIDENT_MODAL

export default {
  modalName: NAME,
  components: {
  },
  opened(event) {
    console.log('Opening...', event)
  },
  watch: {
    title(newValue) {
      if (newValue != "") this.$refs['title'].classList.remove('invalid')
    },
    description(newValue) {
      if (newValue != "") this.$refs['description'].classList.remove('invalid')
    },
  },
  data() {
    return {
      modalName: NAME,
      title: "",
      description: "",
      locationRef: this.$refs.location,
      selectedLocation: "",
      showLocations: false,
      locations: [
        "Clubhouse",
        "Front Gate",
        "Back Gate",
        "Pool",
        "Basketball Court",
        "Tennis Court"
      ],
      selectedType: "",
      showTypes: false,
      types: [
        "Tresspassing",
        "Robbery",
        "Violation",
        "Assault"
      ],
      selectedPriority: "",
      showPriorities: false,
      priorities: [
        "Urgent",
        "High",
        "Medium",
        "Low"
      ],
      selectedStatus: "",
      showStatuses: false,
      statuses: [
        "OPEN",
        "IN PROGRESS",
        "BLOCKED",
        "CLOSED"
      ],
      currentUser: {
        "uid": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
        "first_name": "Richard",
        "last_name": "Dale (Guard)",
        "address": "16303 SW 39th St Miramar FL, 33027",
        "primary_email_address": "richard@communitylp.com",
        "secondary_email_address": "",
        "primary_phone_number": "+19548052619",
        "secondary_phone_number": "",
        "primary_account": 1,
        "primary_account_id": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
        "security_question": "Dog's Name",
        "security_question_answer": "Memo",
        "lifesaving_equipment": "N/A",
        "mobility_assistance": "N/A",
        "subdivision": "Marina Isle",
        "role": "resident",
        "notification_token": ""
      },
      showResidents: false,
      residents: [
        {
          "uid": "618a1a95-4795-4473-bf56-4e115e5c6aaa",
          "first_name": "Richard",
          "last_name": "Dale",
          "address": "16707 SW 39th St Miramar FL, 33027",
          "primary_email_address": "richard@communitylp.com",
          "secondary_email_address": "",
          "primary_phone_number": "+19548052619",
          "secondary_phone_number": "",
          "primary_account": 1,
          "primary_account_id": "618a1a95-4795-4473-bf56-4e115e5c6aaa",
          "security_question": "Dog's Name",
          "security_question_answer": "Memo",
          "lifesaving_equipment": "N/A",
          "mobility_assistance": "N/A",
          "subdivision": "Fantasy Isle",
          "role": "resident",
          "notification_token": ""
        },
        {
          "uid": "835d1084-d9a4-46d2-947b-f790b3d7ab3c",
          "first_name": "Richard",
          "last_name": "Dale",
          "address": "16707 SW 39th St Miramar FL, 33027",
          "primary_email_address": "richard@communitylp.com",
          "secondary_email_address": "",
          "primary_phone_number": "+19548052619",
          "secondary_phone_number": "",
          "primary_account": 1,
          "primary_account_id": "835d1084-d9a4-46d2-947b-f790b3d7ab3c",
          "security_question": "Dog's Name",
          "security_question_answer": "Memo",
          "lifesaving_equipment": "N/A",
          "mobility_assistance": "N/A",
          "subdivision": "Fantasy Isle",
          "role": "resident",
          "notification_token": ""
        },
        {
          "uid": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
          "first_name": "Richard",
          "last_name": "Dale",
          "address": "16303 SW 39th St Miramar FL, 33027",
          "primary_email_address": "richard@communitylp.com",
          "secondary_email_address": "",
          "primary_phone_number": "+19548052619",
          "secondary_phone_number": "",
          "primary_account": 1,
          "primary_account_id": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
          "security_question": "Dog's Name",
          "security_question_answer": "Memo",
          "lifesaving_equipment": "N/A",
          "mobility_assistance": "N/A",
          "subdivision": "Marina Isle",
          "role": "resident",
          "notification_token": ""
        }
      ],
      selectedResident: null,
      selectedAssignee: null,
      showAssignees: false
    }
  },
  methods: {
    show() {
      this.$modal.show(NAME);
    },
    beforeOpen(event) {
      console.log('Before Opening...', event)
    },
    validateKey() {
      console.log("Validating key...")
    },
    close() {
      this.$modal.hide(NAME);
    },
    getReporterText() {
      return `${this.currentUser.first_name} ${this.currentUser.last_name}`
    },
    setLocation(location) {
      this.selectedLocation = location;
      this.showLocations = false;
      this.$refs['location'].classList.remove('invalid')
    },
    setType(type) {
      this.selectedType = type;
      this.showTypes = false;
      this.$refs['type'].classList.remove('invalid')
    },
    setPriority(priority) {
      this.selectedPriority = priority;
      this.showPriorities = false;
      this.$refs['priority'].classList.remove('invalid')
    },
    setStatus(status) {
      this.selectedStatus = status;
      this.showStatuses = false;
      this.$refs['status'].classList.remove('invalid')
    },
    setSelectedResident(resident) {
      this.selectedResident = resident;
      this.showResidents = false;
      this.$refs['resident'].classList.remove('invalid')
    },
    setAssignee(user) {
      this.selectedAssignee = user;
      this.showAssignees = false;
      this.$refs['assignee'].classList.remove('invalid')
    },
    getSelectedResidentText() {
      if (this.selectedResident === null) return ""
      else return `${this.selectedResident.first_name} ${this.selectedResident.last_name} (${this.selectedResident.subdivision})`
    },
    getSelectedAssigneeText() {
      if (this.selectedAssignee === null) return ""
      else return `${this.selectedAssignee.first_name} ${this.selectedAssignee.last_name}`
    },
    inputsValid() {
      let selectedResidentVerified = this.selectedResident != null;
      let selectedTypeVerified = this.selectedType != "";
      let selectedTitleVerified = this.title != "";
      let selectedDescriptionVerified = this.description != "";
      let selecetdReporterVerified = this.currentUser != null;
      let selecetdStatusVerified = this.selectedStatus != "";
      let selecetdPriorityVerified = this.selectedPriority != "";
      let selecetdLocationVerified = this.selectedLocation != "";
      let selectedAssigneeVerified = this.selectedAssignee != null;
      let warnings = []

      if (!selectedTitleVerified) {
        warnings.push("Input a title for this incident")
        this.$refs['title'].classList.add('invalid')
      }
      if (!selectedDescriptionVerified) {
        warnings.push("Input a description for this incident")
        this.$refs['description'].classList.add('invalid')
      }
      if (!selecetdLocationVerified) {
        warnings.push("Select a location for the incident")
        console.log("Location Ref", this.$refs['location'])
        this.$refs['location'].classList.add('invalid')
      }
      if (!selecetdReporterVerified) {
        warnings.push("Sign in to Create an Incident")
      }
      if (!selectedTypeVerified) {
        warnings.push("Select a type for the incident")
        this.$refs['type'].classList.add('invalid')
      }
      if (!selecetdStatusVerified) {
        warnings.push("Select a status for the incident")
        this.$refs['status'].classList.add('invalid')
      }
      if (!selecetdPriorityVerified) {
        warnings.push("Select a priority for the incident")
        this.$refs['priority'].classList.add('invalid')
      }
      if (!selectedResidentVerified) {
        warnings.push("Select an associated Resident")
        this.$refs['resident'].classList.add('invalid')
      }
      if (!selectedAssigneeVerified) {
        warnings.push("Select an Assignee")
        this.$refs['assignee'].classList.add('invalid')
      }

      if (warnings.length != 0) {
        alert(warnings.join('\n'))
        return false
      }
      return true
    },
    createIncident() {
      if (!this.inputsValid()) {
        return;
      }

      let url = `https://2r7ags60v2.execute-api.us-east-2.amazonaws.com/stage/nautica2/incidents`
      return new Promise((resolve, reject) => {
        fetch(
          url,
          {
            method: `POST`,
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "resident_uid": this.selectedResident.uid,
              "incident_type": this.selectedType,
              "incident_title": this.title,
              "description": this.description,
              "reporter": this.currentUser.uid,
              "current_status": this.selectedStatus,
              "priority": this.selectedPriority,
              "location": this.selectedLocation
            })
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log("Search Data", data);
            this.close()
            resolve(data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  created() {
    console.log("In modal")
    console.log("Create Incident Modal Params", this.$$props)
  },
}
</script>

<style scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #699991;
  color: white;
  border-radius: 4px;
}

.modal-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  color: black;
  border: 1px solid black;
}

.modal-header h3 {
  font-size: 36px;
}

.modal-content {
  padding: 2rem;
}

.modal-intro-information {
  display: flex;
  flex-direction: column;
}

.modal-intro-information label {
  color: black;
  text-align: left;
  text-decoration: underline;
}

.modal-incident-title {
  font-size: 24px;
  margin-bottom: 18px;
  border-bottom: 1px solid black;
  text-decoration: none;
  outline: 0;
}

.modal-incident-title::placeholder {
  color: #d9d9d988;
}

.modal-incident-description {
  font-size: 14px;
  font-weight: lighter;
  border-bottom: 1px solid black;
  text-decoration: none;
  outline: 0;
  margin-bottom: 18px;
  resize: none;

}

.modal-incident-description::placeholder {
  color: #d9d9d988;
}

.modal-dropdown-button {
  color: black;
  background-color: #d9d9d9;
  padding: 4px 8px;
  margin-right: 10px;
  border: 1px solid black;
  border-radius: 4px;
}

.modal-create-data {
  margin-top: 41px;
  display: flex;
  flex-direction: column;
}

.modal-create-data-item {
  display: flex;
  flex-direction: row;
  color: black;
  border: 1px solid black;
  height: 36px;
  border-radius: 4px;
}

.modal-create-data-item p {
  align-content: center;
  text-align: left;
  font-weight: bold;
  flex: 1;
  padding-left: 8px;
  border-right: 1px solid black;
}

.modal-create-data-item div {
  flex: 1;
  background-color: #d9d9d9;
}

.modal-create-data-item button {
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: #ccd9d9d9;
}

.modal-create-data-item div ul {
  height: 100%;
}

.modal-create-data-item div li {
  position: relative;
  flex: 1;
  list-style: none;
  text-decoration: none;
  background-color: white;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-bottom: 1px solid #ccd9d9d9;

}

.modal-create-data-item div li:hover {
  background-color: #699991;
}

.modal-create-data-item input {
  flex: 1;
  background-color: #d9d9d9;
  text-align: center;
}

.invalid {
  outline: 1px solid red;
}

.modal-footer {
  padding: 1rem;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 4px;
  color: black;
  border: 1px solid black;
}

.modal-footer button {
  background-color: #699991;
  padding: 4px 24px;
  border-radius: 4px;
  border: 1px solid black;
  font-weight: bold;

}
</style>