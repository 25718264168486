<template>
  <modal :name="modalName" transition="fade" width="650" height="auto" draggable="true" :adaptive="true">
    <div class="modal-container">
      <div class="modal-header">
        <div class="corner-container left">
          <div>
            <div class="header-label"> <b>Nautica</b> </div>
          </div>
          <div>
            <div class="helper-text"> <b>Severity Level</b> </div>
            <p class="helper-text-data">{{ payload.priority }}</p>
          </div>
          <div>
            <div class="helper-text"> <b>Incident Location</b> </div>
            <p class="helper-text-data">{{ payload.location }}</p>
          </div>
        </div>
        <div>
          <div class="circle">
            <button style="width: 100%; height: 100%;" @click="changeAssignee()">
              {{ `${payload.reporter[0].toUpperCase()}${payload.reporter[1].toUpperCase()}` }}
            </button>
          </div>
          <b>
            <p>Assigned Personnel</p>
          </b>
        </div>

        <div class="corner-container right">
          <button class="header-label" @click="close">X</button>
          <div>
            <div class="helper-text"> <b>Resolution Status</b> </div>
            <p class="helper-text-data">{{ payload.current_status }}</p>
          </div>
          <div>
            <div class="helper-text"> <b>Incident ID</b> </div>
            <p class="helper-text-data">{{ payload.incident_id }}</p>
          </div>
        </div>

      </div>
      <div class="modal-fields">
        <div class="modal-field">
          <div class="helper-text"> Incident Title </div>
          <p class="center-text">{{ payload.title }}</p>
        </div>
        <div class="modal-field">
          <div class="helper-text"> Incident Description </div>
          <p class="left-text">{{ payload.description }}</p>
        </div>
        <div class="modal-field">
          <div class="helper-text"> Reported By </div>
          <p class="center-text">{{ payload.reporter }}</p>
        </div>
        <div class="modal-field">
          <div class="helper-text"> Update Log </div>
           <ul style="margin: auto">
            <li class="left-text update-list-item" v-for="update in payload.description.split('.')" :key="update">
              <b>{{ `${date.toLocaleDateString()} ${date.toLocaleTimeString()}` }}</b><br />{{ update }}
            </li>
          </ul>
        </div>
      </div>


      <div class="additional-data">
        <div class="additional-data-item">
          <b>
            <p>Time Created</p>
          </b>
          {{ `${date.toLocaleDateString()} \n ${date.toLocaleTimeString()}` }}
        </div>
        <div style="display:block; width: 1px; height: 100%; background-color: white;"></div>
        <div class="additional-data-item">
          <b>
            <p>Last Update</p>
          </b>
          {{ `${date.toLocaleDateString()} \n ${date.toLocaleTimeString()}` }}
        </div>
        <div style="display:block; width: 1px; height: 100%; background-color: white;"></div>
        <div class="additional-data-item">
          <b>
            <p>Resolution Date</p>
          </b>
          {{ `${date.toLocaleDateString()} \n ${date.toLocaleTimeString()}` }}
        </div>
        <div style="display:block; width: 1px; height: 100%; background-color: white;"></div>
        <div class="additional-data-item">
          <b>
            <p>Attachments</p>
          </b>
          <button @click="uploadAttachment()">
            <img style="margin-top: 8px;" :src="require('../../assets/Icons/paper-clip.png')" alt="">

          </button>
        </div>
      </div>


      <div v-if="payload.role != 'admin'">
        <div class="button-container">
          <button class="action-button" @click="addComment()"> Add Comment </button>
          <button class="action-button suspended-button" @click="updateIncident()"> Update Incident </button>
        </div>
      </div>
      <div v-else>
        <div class="button-container">
          <button class="action-button" @click="editUser()"> Edit User </button>
          <button class="action-button" @click="addSecondaryUser()"> Add Secondary User </button>
        </div>
        <button class="suspended-button " @click="suspendUser()"> Suspend User </button>
      </div>

    </div>
  </modal>
</template>

<script>
import * as Constants from '../../js/constants'
const NAME = Constants.INCIDENT_MODAL

export default {
  modalName: NAME,
  components: {
  },
  props: ['incident'],
  watch: {
    incident: function (newVal) {
      this.payload = newVal;
    },
  },
  data() {
    return {
      modalName: NAME,
      suspended: false,
      inactive: true,
      showId: false,
      date: new Date(),
      payload: this.$props["incident"]
    }
  },
  methods: {
    show() {
      this.$modal.show(NAME);
    },
    close() {
      this.$modal.hide(NAME);
    },
    showKey() {
      console.log("show key...")
    },
    showIncidents() {
      console.log("show incidents...")
    },
    edit() {
      console.log("edit mode!")
    },
    editUser() {
      console.log("edit user...")
    },
    addSecondaryUser() {
      console.log("add user...")
    },
    suspendUser() {
      console.log("suspend user...")
    },
    showUserId() {
      this.showId = true
    },
    hideUserId() {
      this.showId = false
    },
    uploadAttachment() {
      alert("Upload Attachment")
    },
    changeAssignee(){
      alert("Change Assignee")
    },
    addComment() {
      alert("Add Comment")

    },
    updateIncident() {
      alert("Update Incident")

    }
  },
  created() {
    console.log("In modal")

    // make API request here to fill out payload fields
    // fetch or use axios?
  },
  mounted() {
    console.log("Incident Modal", this.$data)

  }
}
</script>

<style>
.modal-container {
  display: flex;
  flex-direction: column;
  background-color: #699991;
  color: white;
  border-radius: 4px;
}

.modal-header {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-fields {
  height: 350px;
  padding: 10px;
  overflow-y: scroll;
  scrollbar-color: #d9d9d9 #699991
}

.modal-field {
  padding: 1rem;
  border-bottom: 1px solid #87BAB7;
}

.modal-time-info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.modal-time-inner {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-right: 1px solid #87BAB7;
  border-left: 1px solid #87BAB7;
}

.header-label {
  margin-bottom: 18px;
}

.helper-text {
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 16px;
}

.helper-text-data {
  margin-bottom: 16px;
}

.center-text {
  font-size: 14px;
  align-items: center;
  text-align: center;
}

.left-text {
  font-size: 14px;
  text-align: left;
  align-items: flex-start;
}

.update-list-item {
  list-style: none;
}

.additional-data {
  height: 90px;
  display: flex;
  flex-direction: row;
  margin: 0rem 1rem;
  padding-top: 10px;
  border-top: 1px solid #87bab7;
}

.additional-data-item {
  flex: 1;
  white-space: pre-line;
}

.circle {
  width: 160px;
  height: 160px;
  line-height: 160px;
  border-radius: 50%;
  font-size: 50px;
  color: #fff;
  text-align: center;
  background: #313E50;
  border: 1px solid #87bab7;
}

.button-container {
  display: flex;
  flex-direction: row;
  margin: 0px 24px 0px 24px;
}

.action-button {
  margin: .5rem;
  color: white;
  background: #313E50;
  width: 45%;
  margin-left: auto;
  margin-right: auto;
  height: 3rem;
  border-radius: 8px;
  font-weight: bold;
}

.suspended-button {
  background-color: #8A0002;
}

.suspended-text {
  color: red;
}

.corner-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.left {
  align-items: flex-start;
}

.left div p {
  text-align: start;
}

.right {
  align-items: flex-end;
}

.right div p {
  text-align: end;

}

.mini-text {
  padding-top: 5px;
  font-size: 12px;
}
</style>
