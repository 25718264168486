<template>
  <div class="contentPane">
    <ActiveContent />
    <CreateVisitorModal />
    <CreateIncidentModal />
    <IncidentModal :incident="incidentData"/>
  </div>
</template>

<script>
import ActiveContent from "./ActiveContent.vue";
import CreateVisitorModal from "./modals/CreateVisitorModal.vue";
import CreateIncidentModal from "./modals/CreateIncidentModal.vue";
import IncidentModal from "./modals/IncidentModal.vue";
import EventBus from "../eventBus.js";
import * as Constants from "../js/constants.js"

export default {
  name: "Content-Pane",
  components: {
    ActiveContent,
    CreateVisitorModal,
    CreateIncidentModal,
    IncidentModal,
  },
  data() {
    return {
      incidentData: {
        "incident_id": 38,
        "incident_type": "Assault",
        "title": "Jason Test Incident",
        "description": "At 8:30 am, resident Richard Dale slipped at the Nautica Pool. He was running on the pool deck and fell by the showers.\nRichard was drinking and this might have been the reason for the fall. Paramedics were called since he cracked open his head",
        "reporter": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
        "reporter_name": "Jason Quintana",
        "resident_uid": "fa9c6911-44e8-4cf6-9cfd-96b089c95be9",
        "current_status": "IN PROGRESS",
        "priority": "Urgent",
        "location": "Pool",
        "time_created": "2024-12-18T16:25:54.000Z",
        "time_acknowledged": null,
        "time_last_updated": "2024-12-18T16:25:54.000Z",
        "time_closed": null,
        "closed": 0,
        "closed_reason": null
      }
    }
  },
  methods: {
    updateIncidentData(data) {
      console.log("Updating Incident Data", data)
      this.incidentData = data
      console.log("New Incident Data", this.incidentData)
    }
  },
  created() {
    EventBus.$on(Constants.UPDATE_INCIDENT_MODAL_DATA,(data) => this.updateIncidentData(data));
  }
};
</script>

<style scoped>
.contentPane {
  width: calc(100% - 200px);
  margin: 0px;
  display: block;
  min-height: 88%;
  max-height: 90%;
  float: right;
  flex-shrink: 0;
  flex-grow: 1;
  flex-basis: auto;
}
</style>