<template>
    <modal
      :name="modalName"
      transition="fade"
      width="700"
      height="auto"
      draggable="true"
      @before-open="beforeOpen"
      :adaptive="true">
      <div class="modal-container">
        <div class="modal-header">
          {{ payload.subdivision }}
          <div class="circle">{{ payload.first_name[0] + payload.last_name[0] }}</div>
  
          Scanned 
          <!-- what else here?? ^ -->
        </div>
        <div class="modal-field">
          <div class="helper-text"> Resident Name </div>
          {{ payload.first_name + ' ' + payload.last_name }}
        </div>
        <div class="modal-field">
          <div class="helper-text"> Guest Name </div>
          {{ payload.visitor_name }}
        </div>
        <div class="modal-field">
          <div class="helper-text"> Address </div>
          {{ payload.address }}
        </div>
        <div class="modal-field">
          <div class="helper-text"> Resident Phone Number </div>
          {{ payload.phone }}
        </div>
        <div class="modal-time-info">
          <div class="modal-time-inner"> 
            <div> Sent </div>
            <div style="padding: 1rem;"> {{ payload.time_key_sent || '-------' }} </div>
          </div>
          <div class="modal-time-inner"> 
            <div> Active </div>
            <div style="padding: 1rem;"> {{ payload.time_active || '-------' }} </div>
          </div>
          <div class="modal-time-inner"> 
            <div> Expiration </div>
            <div style="padding: 1rem;"> {{ payload.time_expired || '-------' }} </div>
          </div>
          <div class="modal-time-inner"> 
            <div> Validated </div>
            <div style="padding: 1rem;"> {{ payload.time_validated || '-------' }} </div>
          </div>
        </div>
        
        <button class="validate-button" @click="validateKey()"> Validate Key </button>
      </div>
    </modal>
  </template>
  
  <script>
    import * as Constants from "../../js/constants.js";
    const NAME = Constants.CREATE_VISITOR_MODAL
  
    export default {
      modalName: NAME,
      components: {
      },
      opened (event) {
        console.log('Opening...', event)
      },
      data () {
        return {
          modalName: NAME,
          payload: {
            "key_id": "953bc61bcfa3",
            "user_uid": "38bf5676-eee0-4103-81a7-f812bb21d14e",
            "visitor_name": "Henry Metral",
            "visitor_number": "8507586114",
            "link_url": "https://api.qrserver.com/v1/create-qr-code/?data=953bc61bcfa3&size=500x500",
            "current_status": "VALIDATED",
            "expiration": "2020-10-02T03:03:00.000Z",
            "validator_uid": "community_callbox_scanner",
            "time_active": "2020-10-02T00:03:00.000Z",
            "date_active": null,
            "time_created": "2020-10-02T00:43:54.000Z",
            "time_key_sent": "2020-10-02T00:43:54.000Z",
            "time_validated": "2020-10-02T00:44:28.000Z",
            "time_expired": null,
            "expired": 0,
            "party_id": null,
            "party_key": 0,
            "party_name": null,
            "uid": "38bf5676-eee0-4103-81a7-f812bb21d14e",
            "first_name": "Richard",
            "last_name": "Dale",
            "email": "rmd127@miami.edu",
            "phone": "9548052619",
            "address": "4143 43rd St, Sunnyside, NY 11104",
            "subdivision": "Fantasy Isle",
            "role": "resident",
            "notification_token": "ExponentPushToken[0k09k-Gs2cjrUr16ZjrZ02]"
          }
        }
      },
      methods: {
        show () {
          this.$modal.show(NAME);
        },
        beforeOpen (event) {
        console.log('Before Opening...', event)
      },
        validateKey () {
          console.log("Validating key...")
        },
      },
      created () {
        console.log("In modal")
        console.log("Gate Modal Params", this.$$props)
      },
    }
  </script>
  
  <style>
    .modal-container {
      display: flex;
      flex-direction: column;
      background-color: #699991;
      color: white;
      border-radius: 4px;
    }
    .modal-header {
      padding: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .validate-button {
      color: white;
      background-color: #87BAB7;
      width: 60%;
      margin-left: auto;
      margin-right: auto;
      height: 2rem;
      border-radius: 5px;
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
    .modal-field {
      padding: 1rem;
      font-size: 25px;
      border-bottom: 1px solid #87BAB7;
    }
    .modal-time-info {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }
    .modal-time-inner {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      border-right: 1px solid #87BAB7;
      border-left: 1px solid #87BAB7;
    }
    .helper-text {
      text-align: left;
      font-size: 15px;
    }
    .circle {
      width: 160px;
      height: 160px;
      line-height: 160px;
      border-radius: 50%;
      font-size: 50px;
      color: #fff;
      text-align: center;
      background: #87BAB7;
    } 
  </style>
  