<template>
  <div class="search-container">
    <svg v-if="searchIcon" class="smallIcon searchIcon" fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 490.4 490.4" xml:space="preserve">
      <g>
        <path
          d="M484.1,454.796l-110.5-110.6c29.8-36.3,47.6-82.8,47.6-133.4c0-116.3-94.3-210.6-210.6-210.6S0,94.496,0,210.796
		s94.3,210.6,210.6,210.6c50.8,0,97.4-18,133.8-48l110.5,110.5c12.9,11.8,25,4.2,29.2,0C492.5,475.596,492.5,463.096,484.1,454.796z
		M41.1,210.796c0-93.6,75.9-169.5,169.5-169.5s169.6,75.9,169.6,169.5s-75.9,169.5-169.5,169.5S41.1,304.396,41.1,210.796z" />
      </g>
    </svg>
    <input type="text" :placeholder="localPlaceholder" :style="localStyle" class="selectorContent"
      v-model="localSearch" />
  </div>


</template>


<script>


export default {
  name: "SearchBar",
  props: ["search", "searchCallback", "placeholder", "inputStyle", "searchIcon"],
  watch: {
    localSearch(newVal, oldVal) {
      let newSearchTime = new Date().getTime();
      if (newVal !== oldVal && newVal.length > 2 && newSearchTime - this.searchLastUpdated > 1500) {
        console.log("Last Search", this.searchLastUpdated);
        this.searchLastUpdated = newSearchTime;
        this.$props.searchCallback(newVal); // Call the parent's callback with the new value
      }
    },
    search(newVal) {
      // Sync the prop value to the local data when the parent changes the prop
      this.localSearch = newVal;
    },
  },
  data() {
    return {
      localSearch: this.search, // Initialize the localSearch with the prop value
      searchLastUpdated: new Date().getTime(),
      localPlaceholder: this.$props.placeholder ?? "Search",
      localStyle: this.$props.inputStyle ?? "",
      localSearchIcon: this.$props.searchIcon ?? false,
    }
  }


}

</script>


<style>
.selectorContent {
  width: 100%;
  font-size: 20px;
  font-weight: 550;
  border: 0;
  outline: 0;
}

.search-container {
  width: 100%;
  text-align: left;
  flex-direction: row;
  display: flex;
}

.searchIcon {
  margin-right: 4px;
}

::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: black;
  font-weight: 550;
}
</style>