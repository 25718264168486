<template>
  <modal
    :name="modalName"
    transition="fade"
    width="700"
    height="auto"
    draggable="true"
    :adaptive="true">
    <div class="modal-container">
      <div class="modal-header">
        <div class="corner-container">
          <div>
            {{ payload.subdivision }} 
          </div>
          <div class="mini-text">
            {{ payload.role }} 
          </div>
        </div>

        <div class="circle">{{ payload.first_name[0] + payload.last_name[0] }}</div>

        <div class="corner-container">
          <div>
            <div class="suspended-text" v-if="payload.status == 'suspended'">Suspended</div> 
            <div class="suspended-text" v-else-if="payload.status=='inactive'">Inactive</div> 
            <div v-else>Active</div> 
            <!-- Assuming that a 'status' field will be coming in from API request  ^ -->
          </div>
          <div class="mini-text">
            Last Interaction: {{  }} 
          </div>

          <div class="mini-text" @click=showUserId() v-if="!showId">
            Click to see User ID
          </div>
          <div @click=hideUserId() v-else>
            {{ payload.uid }} 
          </div>
        </div>

      </div>
      <div class="modal-field">
        <div class="helper-text"> Resident Name </div>
        {{ payload.first_name + ' ' + payload.last_name }}
      </div>
      <div class="modal-field">
        <div class="helper-text"> Email </div>
        {{ payload.email }}
      </div>
      <div class="modal-field">
        <div class="helper-text"> Address </div>
        {{ payload.address }}
      </div>
      <div class="modal-field">
        <div class="helper-text"> Resident Phone Number </div>
        {{ payload.phone }}
      </div>
      
      <div v-if="payload.role != 'admin'">
        <div class="button-container">
          <button class="action-button" @click="showKey()"> Keys </button>
          <button class="action-button" @click="showIncidents()"> Incidents </button>
        </div>
        <button class="action-button" @click="edit()"> Edit </button>
      </div>
      <div v-else>
        <div class="button-container">
          <button class="action-button" @click="editUser()"> Edit User </button>
          <button class="action-button" @click="addSecondaryUser()"> Add Secondary User </button>
        </div>
        <button class="suspended-button " @click="suspendUser()"> Suspend User </button>
      </div>

    </div>
  </modal>
</template>

<script>
  const NAME = 'users-modal'

  export default {
    modalName: NAME,
    components: {
    },
    data () {
      return {
        modalName: NAME,
        suspended: false,
        inactive: true,
        showId: false,
        payload: {"uid":"38bf5676-eee0-4103-81a7-f812bb21d14e","first_name":"Richard","last_name":"Dale","email":"rmd127@miami.edu","phone":"9548052619","address":"4143 43rd St, Sunnyside, NY 11104","subdivision":"Fantasy Isle","role":"resident","notification_token":"ExponentPushToken[0k09k-Gs2cjrUr16ZjrZ02]","unit_id":null,"carma":1004499, "status": "active"}
      }
    },
    methods: {
      show () {
        this.$modal.show(NAME);
      },
      showKey () {
        console.log("show key...")
      },
      showIncidents () {
        console.log("show incidents...")
      },
      edit () {
        console.log("edit mode!")
      },
      editUser () {
        console.log("edit user...")
      },
      addSecondaryUser () {
        console.log("add user...")
      },
      suspendUser () {
        console.log("suspend user...")
      },
      showUserId () {
        this.showId = true
      },
      hideUserId () {
        this.showId = false
      }
    },
    created () {
      console.log("In modal")
      // make API request here to fill out payload fields
      // fetch or use axios?
    },
  }
</script>

<style>
  .modal-container {
    display: flex;
    flex-direction: column;
    background-color: #699991;
    color: white;
    border-radius: 4px;
  }
  .modal-header {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .modal-field {
    padding: 1rem;
    font-size: 25px;
    border-bottom: 1px solid #87BAB7;
  }
  .modal-time-info {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
  .modal-time-inner {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-right: 1px solid #87BAB7;
    border-left: 1px solid #87BAB7;
  }
  .helper-text {
    text-align: left;
    font-size: 15px;
  }
  .circle {
    width: 160px;
    height: 160px;
    line-height: 160px;
    border-radius: 50%;
    font-size: 50px;
    color: #fff;
    text-align: center;
    background: #87BAB7;
  }
  .button-container {
    display: flex;
    flex-direction: row;
  }
  /* .action-button {
    margin: .5rem;
    color: white;
    background-color: #87BAB7;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    height: 3rem;
    border-radius: 5px;
  }
  .suspended-button {
    margin: .5rem;
    color: white;
    background-color: red;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    height: 3rem;
    border-radius: 5px;
  } */
  .suspended-text {
    color: red;
  }
  .corner-container {
    display: flex;
    flex-direction: column;
  }
  .mini-text {
    padding-top: 5px;
    font-size: 12px;
  }
</style>
